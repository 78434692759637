import { render, staticRenderFns } from "./freezerMsgStatistics.vue?vue&type=template&id=04a99abc&"
import script from "./freezerMsgStatistics.vue?vue&type=script&lang=js&"
export * from "./freezerMsgStatistics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports